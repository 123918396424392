
	frappe.templates['esignature_menu'] = `<ul class="list-unstyled sidebar-menu form-esignature esignature-menu">
	<li class="sidebar-label esignature-menu--header">
		<svg class="icon icon-sm"><use href="#icon-edit"></use></svg>
		{%= __("eSignatures") %}
	</li>
	<li>
		<button class="data-pill btn esignature-menu--request-btn">
			<span class="pill-label ellipsis">
				{%= __("Request an eSignature") %}
			</span>
			<svg class="icon icon-sm">
				<use href="#icon-mail"></use>
			</svg>
		</button>
	</li>
</ul>
`;
